import { Button, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';

type DownloadSignedDocumentProps = {
  downloadLink: string;
  disabled?: boolean;
};

const DownloadSignedDocument = ({
  downloadLink,
  disabled,
}: DownloadSignedDocumentProps) => {
  const { translate } = useLocales();

  return (
    <Button
      variant="contained"
      color="primary"
      href={downloadLink}
      target="_blank"
      disabled={disabled}
    >
      <Typography>
        {String(translate('global.dashCardInfos.guest.getDocButton'))}
      </Typography>
    </Button>
  );
};

const ViewService = ({
  toServiceURL,
  ownerID,
  disabled,
}: {
  toServiceURL: string;
  ownerID?: number | string;
  disabled?: boolean;
}) => {
  const { translate } = useLocales();

  return (
    <Link component={RouterLink} to={toServiceURL} state={{ ownerID }}>
      <Button variant="contained" color="primary" disabled={disabled}>
        <Typography>
          {String(translate('global.dashCardInfos.viewData'))}
        </Typography>
      </Button>
    </Link>
  );
};

export { DownloadSignedDocument, ViewService };
