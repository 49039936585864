import { Stack } from '@mui/material';
import {
  DownloadSignedDocument,
  ViewService,
} from '#/components/pages/SuccessorDashboard/extras/card-actions';

type ExtrasProps = {
  serviceDoc: string;
  docType?: boolean;
  viewURL?: string;
  ownerID?: string;
  disabled?: boolean;
};

export default function Extras({
  serviceDoc,
  docType,
  viewURL,
  ownerID,
  disabled,
}: ExtrasProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      {docType && (
        <DownloadSignedDocument disabled={disabled} downloadLink={serviceDoc} />
      )}
      {viewURL && (
        <ViewService
          disabled={disabled}
          toServiceURL={viewURL}
          ownerID={ownerID}
        />
      )}
    </Stack>
  );
}
