import React from 'react';
import { Box } from '@mui/material';
import serviceCards from '#/components/pages/SuccessorDashboard/serviceCards';
import DashboardCard from '#/components/shared/dashboard-card';
import Extras from '#/components/pages/SuccessorDashboard/extras/extras';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';

export default function SServicesList() {
  const { translate } = useLocales();
  const { currentAccount } = useAccountSwitch();

  const accessDetails = currentAccount?.invitation_details;
  const generalAccess = accessDetails?.general_access_right;
  const hasSpecificAccess = accessDetails?.has_specific_access;
  const userReportedDead =
    currentAccount?.docs?.reported_as_dead_status === 'APPROVED';

  const serviceRestrictions = (serviceAccessKey: string) =>
    accessDetails?.specific_service_access?.find(
      (access: any) => access?.service === serviceAccessKey
    );

  const onAccessDisable = (serviceAccessKey: string) => {
    if (!serviceRestrictions(serviceAccessKey)) {
      return !userReportedDead && generalAccess === 'after-death';
    }
    if (hasSpecificAccess && serviceRestrictions(serviceAccessKey)) {
      const serviceRestrictionLevel =
        serviceRestrictions(serviceAccessKey)?.accessLevel;

      if (serviceRestrictionLevel === 'while-alive') {
        return false;
      }
      return serviceRestrictionLevel === 'after-death' && !userReportedDead;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 2,
      }}
    >
      {serviceCards.map((card, i) => (
        <DashboardCard
          title={card.title}
          icon={card.icon}
          description={card.description}
          key={`${card.title + i}`}
          tooltipTitle={String(
            translate('global.dashCardInfos.guest.disabledButton')
          )}
          showTooltip={onAccessDisable(card.accessKey as string)}
          actions={
            <Extras
              serviceDoc={currentAccount?.docs[card.serviceKey as string] || {}}
              {...card}
              ownerID={currentAccount?.id}
              disabled={onAccessDisable(card.accessKey as string)}
            />
          }
        />
      ))}
    </Box>
  );
}
